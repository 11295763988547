
.response-form {
  &-heading {
    @extend .section-question;
    margin-top: 1rem !important;
  }
  textarea,
  textarea:focus,
  input,
  input:focus {
    background: #262727;
    border-radius: 0;
    border: 1px solid #888a8a;
    color: white;
    height: auto;
  }
  input,
  input:focus {
    height: 50px;
  }
  button {
    width: 100%;
    height: 50px;
    border-radius: 0;
    color: #262727;
    font-weight: bold;
  }
  a,
  a:hover {
    color: white;
  }
  .verticalLine {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
  }
}


.nav-tabs {
  border: 1px solid #dee2e6;
  display: flex;
  justify-content: space-around;
  padding: 1em;
  height: auto;
  .nav-item.show .nav-link,
  .nav-link {
    border-radius: 50px;
    height: 30px;
    padding-top: 6px;
    background: $baseColour;
    color: white;
    flex-grow: 1;
    text-align: center;
    margin: 0 8px;
    border: none;
    cursor: pointer;
    transition: color $transition-duration ease, background-color $transition-duration ease;
  }
  .nav-link {
    .active,
    &:focus,
    &:hover {
      background-color: $white;
      color: $baseColour;
    }
  }
}

.nav-link {
  margin-left: 25px;
}

.small-heading,
.nav-tabs {
  font-weight: bold;
  font-size: 12.5px;
}