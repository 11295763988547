.divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgba($white, .5);
  margin: 2em 0;
  padding: 0;
  margin-left: 15px;
  width: 85%;
}

.miller-italic {
  font-family: 'miller';
  font-size: 18px;
  letter-spacing: 1.5px;
}

.verticalLine {
  //border-left: 1px solid rgba($white, .5);
  height: 90px;
  margin-left: calc(69px + 0%);
  margin-right: 0;
  margin-top: 20px;
  margin-bottom: 35px;
  position: relative;
  &::after {
    content: '';
    display: block;
    background-color: rgba($white, .5);
    height: 90px;
    width: 1px;
    position: absolute;
    top: 0;
    left: 6.6%;
  }
}

.loader {
  width: 10%;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}

.alert {
  border-radius: 0;
  background-color: #fff;
  border: none;
  color: #262727;
  font-weight: bold;
  padding: 1.75rem 1.25rem;
}


// Splitting JS

.splitting .char {
  display: inline-block;
  position: relative;
  opacity: 0;
}
.splitting .word {
  display: inline-block;
}
.splitting .char:after, .splitting .char:before {
  content: attr(data-char);
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: inherit;
  user-select: none;
}