body {
  background: $baseColour;
  color: $white;
  font-family: $font-family-sans-serif;
}
h1{
  color: white;
}
hr {
  margin-top: 4rem;
  margin-bottom: 4rem;
  border-top: none;
  position: relative;
  height: 1px;
  animation: fadein 3s forwards;
  &::before {
    opacity: .5;
    content: '';
    position: absolute;
    height: 1px;
    right: -45px;
    left: 0;
    background-color: #ffffff;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
::selection {
  background-color: #fff;
  color: black;
}
p {
  max-width: 500px;
}
b, strong{
  font-weight: bold;
}
a {
  color: currentColor;
  cursor: pointer;
  transition: opacity $transition-duration ease;
  position: relative;
  display: inline-block;
  &:hover,
  &:active {
    color: currentColor;
    opacity: .9;
    text-decoration: none;
  }
  &:not(.nav-item):not(.header-container--link):not(.video-mute-toggle) {
    &::after {
      display: block;
      content: '';
      opacity: 1;
      width: 100%;
      bottom: .1em;
      height: 1px;
      background: currentColor;
      position: absolute;
      transition: opacity $transition-duration ease, bottom $transition-duration ease;
      pointer-events: none;
    }
    &:hover {
      &::after {
        opacity: 0;
        bottom: -.15em;
      }
    }
    * { // prevent CSS inheritance
      &::after {
        display: none;
      }
    }
  }
}