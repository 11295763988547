.video {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5em;
  &-react {
    .video-react-big-play-button {
      top: 45% !important;
      left: 45% !important;
      border: 0px;
      background-color: transparent;
      &::before {
        font-size: 55px !important;
      }
    }
    &:hover {
      .video-mute-toggle {
        opacity: .6;
      }
    }
  }
  &-captions {
    width: (1050 / 1610 * 100%);
    margin: 0 0 1em auto;
    font-size: 12.5px;
    text-align: right;
  }
  &-mute-toggle {
    position: absolute;
    z-index: 10;
    bottom: 1em;
    left: 1em;
    font-size: 2.5em;
    opacity: 0;
    transition: opacity $transition-duration ease;
    &:hover {
      opacity: 1;
    }
    &:focus {
      outline: none;
    }
  }
}
