@import "~video-react/styles/scss/video-react";

// settings
@import './styles/fonts';
@import './styles/variables';
@import '~bootstrap';

// global
@import './styles/global';

// specific
@import './styles/header';
@import './styles/body';
@import './styles/sections';
@import './styles/pages';
@import './styles/custom';
