.App {
    margin-left: 31.5px;
    margin-right: 45px;
    margin-top: 12px;
    &-header {
        background-color: $baseColour;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
    }
    &-logo {
        float: left;
        width: 42.5px;
        height: 50px;
        margin-right: 27px;
        position: relative;
        &-santa {
            position: absolute;
            left: 2px;
            top: -16px;
            width: 62px;
        }
    }
    &-link {
        color: #09d3ac; // What is this for????
    }
    &-wordmark {
        font-size: 30px;
        line-height: 50px;
    }
}

.header-container {
    margin-top: 40px;
    white-space: nowrap;
}

.main{
    margin-top: 75px;
    &--top{
        .section-number_arrow {
            &,
            &:hover {
                opacity: 0;
                cursor: default;
            }
        }
    }
}
