@font-face {
  font-family: "gilroy";
  font-weight: bold;
  src: url("./../assets/fonts/Gilroy-ExtraBold.otf");
}
@font-face {
  font-family: "gilroy";
  font-weight: 300;
  src: url("./../assets/fonts/Gilroy-Light.otf");
}
@font-face {
  font-family: "miller";
  src: url("./../assets/fonts/Miller-Banner-Italic.otf");
}