.image-gallery {
  text-align: left;
  &-left {
    height: auto;
    z-index: -1;
    &-text {
      min-height: 2em;
    }
    img {
      width: 80%;
    }
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: -2em;
    @media (min-width: 576px) {
      display: block;
      position: absolute;
      img {
        margin-bottom: 1em;
        width: (1015 /1610 * 100%);
      }
    }
  }

  &-right {
    margin-bottom: 2em;
    &-text {
      width: 80%;
      margin: 0 0 0 auto;
      min-height: 2em;
    }
    img {
      width: 80%;
      margin: 0 0 0 auto;
      display: block;
      filter: drop-shadow(0px 0px 50px #25272796);
    }
    display: flex;
    flex-direction: column-reverse;
    @media (min-width: 576px) {
      display: block;
      &-text {
        width: (490 /1610 * 100%);
      }
      img {
        width: (770 /1610 * 100%);
      }
    }
  }

  &-caption{
    &-title {
      font-weight: bold;
      margin-bottom: 1px;
      font-size: .75em;
    }
    &-excerpt {
      font-weight: 300;
      font-size: .75em;
    }
  }
}