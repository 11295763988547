$baseColour: #262727;
$fontColour: #ffffff;
$sectionHeadingFontSize: 16px;
$sectionHeadingFontWeight: bold;
$sectionDescriptionFontSize: 12px;
$sectionDescriptionFontColour: #ffffff;
$white: #ffffff;
$lineColour: rgb(69, 70, 70);
$transition-duration: .4s;


// Bootstrap variables
$font-family-sans-serif: "gilroy", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$grid-gutter-width: 35px !default;
$input-btn-font-size: (12.5 / 16) * 1em;