
@import 'sections/image-gallery';
@import 'sections/text';
@import 'sections/video';
@import 'sections/buttons';
@import 'sections/response-form';
@import 'sections/two-columns';
@import 'sections/three-columns';


.section {
    $p: &;
    &-title {
        font-size: 30px;
        line-height: (34/30);
        font-weight: bold;
        text-align: left;
        margin-bottom: 1.7rem;
    }
    &-question {
        font-size: 20px;
        font-family: 'miller';
        margin-top: 68px;
        margin-bottom: 1.5rem;
    }
    &-number {
        font-size: 10px;
        padding-top: 3px;
        font-weight: bold;
        @extend .col;
        flex: 0 0 8%;
        max-width: 42.5px;
        box-sizing: content-box;
        padding-right: 13.5px;
        p {
            position: sticky;
            top: 2em;
            margin-top: .5em;
        }
        &_arrow {
            display: block;
            height: 40px;
            position: relative;
            opacity: .4;
            cursor: pointer;
            transition: opacity $transition-duration ease, padding $transition-duration ease;
            margin-top: -1em;
            padding-top: 2em;
            padding-bottom: 0;
            &:hover {
                padding-top: 1.5em;
                padding-bottom: 0.5em;
                margin-top: -1em;
                opacity: .75;
                &::before {
                    height: 30px;
                }
            }
            &::before,
            &::after{
                content: '';
                display: block;
                position: absolute;
                transform-origin: center;
            }
            &::before {
                height: 20px;
                width: 2px;
                background-color: currentColor;
                left: calc(50% - 1px);
                transition: height $transition-duration ease, top $transition-duration ease;
            }
            &::after {
                height: 14px;
                width: 14px;
                box-sizing: border-box;
                border-color: currentColor currentColor transparent transparent;
                border-width: 2px;
                border-style: solid;
                left: 50%;
                transform: translateY(-6px) rotate(-45deg) translateX(calc(-50% - 3px));
                transition: height $transition-duration ease, width $transition-duration ease;
            }
        }
        &_prev {
            @extend #{$p}-number_arrow;
            transform: rotate(-90deg);
        }
    }
    &s-holder {
        @extend .col;
        padding-left: 13.5px;
    }
    &-col {
        font-size: 20px;
    }
    &-description {
        font-size: 20px;
        line-height: (25/20);
        color: $sectionDescriptionFontColour;
        line-height: 1.3;
        font-weight: 300;
        text-align: left;
        > *:last-child {
            margin-bottom: 0;
        }
    }
}


::placeholder {
    font-weight: bold;
    color: white;
}

.linedown2 {
    content: "";
    width: 1px;
    margin-top: 20px;
    height: 0;
    margin-bottom: 20px;
    background: #fbf8f6;
    left: 7%;
    position: relative;
    display: none;
}


.linedown2 {
    top: 100%;
    animation: linedown 2s forwards
}
@keyframes lineup {100% {top: -200px; height: 150px}}
@keyframes linedown {100% {height: 150px}}