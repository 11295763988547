.button {
  $p: &;
  position: relative;
  cursor: pointer;
  opacity: 0;
  vertical-align: top;
  opacity: 1;
  margin-bottom: 2rem;
  @extend .col-6;
  @extend .col-md-4;
  @extend .col-lg-3;
  @extend .col-xl-2;
  @media (max-width: 375px) {
    max-width: 100%;
  }
  &-container {
    text-align: left;
    width: 100%;
  }
  &-map {
    display: inline;
    &:hover {
      #{$p}:not(:hover) {
        filter: blur(1px);
        opacity: .8;
      }
    }
    &--loaded #{$p} {
      transition: filter $transition-duration ease, opacity $transition-duration ease;
    }
  }
  &::before {
    content: '';
    display: block;
    padding-top: 100%;
    @media (max-width: 375px) {
      padding-top: 50%;
    }
  }
  &-text {
    font-weight: bold;
    position: absolute;
    top: 0;
    right: 17.5px;
    bottom: 0;
    left: 17.5px;
    border: 1px solid currentColor;
    font-size: 16px;
    line-height: 1.25;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    @media (min-width: 576px) {
      font-size: 20px;
    }
  }
  &--active {
    #{$p}-text {
      background-color: $fontColour;
      color: $baseColour;
    }
  }
}
